body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  background: linear-gradient(89deg, #ff5edf 0%, #04c8de 100%);
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

button {
  cursor: pointer;
}