body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  background: -webkit-linear-gradient(1deg, #ff5edf 0%, #04c8de 100%);
  background: linear-gradient(89deg, #ff5edf 0%, #04c8de 100%);
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

button {
  cursor: pointer;
}

.bounding-box {
  position: absolute;
  box-shadow: inset 0 0 0 3px #149df2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}
.Tilt {
  background: -webkit-linear-gradient(1deg, #ff5edf 0%, #04c8de 100%);
  background: linear-gradient(89deg, #ff5edf 0%, #04c8de 100%);
}
.form {
  width: 700px;
  background:
-webkit-radial-gradient(0% 50%,circle farthest-side,#fb1 23.5%,rgba(240,166,17,0) 0)21px 30px,
-webkit-radial-gradient(0% 50%,circle farthest-side,#B71 24%,rgba(240,166,17,0) 0)19px 30px,
-webkit-linear-gradient(#fb1 14%,rgba(240,166,17,0) 0, rgba(240,166,17,0) 85%,#fb1 0)0 0,
-webkit-linear-gradient(300deg,#fb1 24%,#B71 0,#B71 26%,rgba(240,166,17,0) 0,rgba(240,166,17,0) 74%,#B71 0,#B71 76%,#fb1 0)0 0,
-webkit-linear-gradient(60deg,#fb1 24%,#B71 0,#B71 26%,rgba(240,166,17,0) 0,rgba(240,166,17,0) 74%,#B71 0,#B71 76%,#fb1 0)0 0,
-webkit-linear-gradient(left,#B71 2%,#fb1 0,#fb1 98%,#B71 0%)0 0 #fb1;
  background:
radial-gradient(circle farthest-side at 0% 50%,#fb1 23.5%,rgba(240,166,17,0) 0)21px 30px,
radial-gradient(circle farthest-side at 0% 50%,#B71 24%,rgba(240,166,17,0) 0)19px 30px,
linear-gradient(#fb1 14%,rgba(240,166,17,0) 0, rgba(240,166,17,0) 85%,#fb1 0)0 0,
linear-gradient(150deg,#fb1 24%,#B71 0,#B71 26%,rgba(240,166,17,0) 0,rgba(240,166,17,0) 74%,#B71 0,#B71 76%,#fb1 0)0 0,
linear-gradient(30deg,#fb1 24%,#B71 0,#B71 26%,rgba(240,166,17,0) 0,rgba(240,166,17,0) 74%,#B71 0,#B71 76%,#fb1 0)0 0,
linear-gradient(90deg,#B71 2%,#fb1 0,#fb1 98%,#B71 0%)0 0 #fb1;
background-size: 40px 60px;
/*z-index: 2;*/
}

.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

